.filter-container {
    margin-bottom: 20px;
}
.filter-sort-by-label {
    text-align: right;
}
.filter-sort-by-label span {
    padding-top: 5px;
    display: block;
}
