.h6fnhWdeg-add-publication-search-container {
    background: #efefef;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
}
.h6fnhWdeg-add-publication-search-container button {
    margin-left: -15px;
}

label.year-label {
    padding-right: 0;
    width: 60px;
    display: table-cell;
}