
.show-rows {
    width: 100%;
    display: table;
}
.show-rows select {
    max-width: 100px;
}
.show-rows label {
    display: table-cell;
    padding-right: 10px;
    width: 110px;
}
.pagination-container {
    padding: 0px 10px;
}
.pagination-container .col {
    padding: 10px;
}
.pagination-container ul.pagination {
    margin: 0px;
}