.footer{
    margin-top: 15px;
    padding-top: 10px; 
    color: #ccc;
    font-size: 13px;
    line-height: 1.2em;
    font-weight: 300;
    margin-bottom: 30px;
    text-align: center;
    border-top: 2px solid #f7f7f7;
    
}