.dropdown-menu span {
    display: block;
    padding: 3px 20px 4px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.4em;
    font-size: 14px;
    color: #333;
    white-space: nowrap;
    cursor: pointer;
}
.dropdown-menu span:hover {
    background-color: #efefef;
}
.dropdown-sort-asc::after {
    content: '\2191';
    padding-left: 10px;
}
.dropdown-sort-desc::after {
    content: '\2193';
    padding-left: 10px;
}
