#root{
    height: 100vh;
}

body{
    height: 100vh;
}

#root>div{
    height: 100vh;
}

.login-main-container{
    min-height: 100%;
    display: grid;
    grid-template-rows: [header] auto [form] 1fr [footer] auto;
}

.header{
    grid-row-start: header;
}

.form-container{
    display: grid;
    align-items: center;
    justify-content: center;
}

.login-form{
    display: inline-block; 
    border: 1px solid gray;
    padding: 30px 40px;
    border-radius: 4px; 
}

h3.login-header{
    margin: 0;
    margin-bottom: 20px;
}

.form-control{
    margin-top: 5px;
}

button.login-btn.btn.btn-primary{
    float: right;
    color: white;
    background-color: orange;
    font-weight: 500;
    border-color: orange;
}

.footer{
    grid-row-start: footer;
}
