.topNav{
    background-color: #007bff;
    border-radius: 0;
    margin: 0;
}

.drop-container{
    float: right;
    color: white;
    margin: 15px;
}

/* Dropdown Button */
.drop-btn {
    background-color: #007bff;
    color: white;
    font-size: 10px;
    border: none;
  }
  
  /* The container <div> - needed to position the dropdown content*/
  .dropdown {
    position: relative;
    display: inline-block;
  } 
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    right: 50%;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ddd;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {background-color: #3e8e41;}

