.h6fnhWdeg-tab-controls-container {
    background: #efefef;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
}
.h6fnhWdeg-accept-all {
    margin-right: 10px;
}

.h6fnhWdeg-tab-controls-container .filter-container {
    float: right;
    min-width: 390px;
}
.h6fnhWdeg-tab-controls-container .filter-container input {
    float: right;
    max-width: 200px;
    margin-right: 10px;
}
.h6fnhWdeg-tab-controls-container .filter-container .dropdown {
    float: right;
}
.h6fnhWdeg-tab-controls-container .btn-default.disabled {
    color: #777;
    background-color: #ffffff;
    border-color: #ccc;
}
