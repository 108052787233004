.h6fnhWdeg-main-container {
    font-family: 'Helvetica Neue', Helvetica;
}


.h6fnhWdeg-publication-field {
    line-height: 1.3em !important;
}


.h6fnhWdeg-publication-citation {
    display: none;
}

.h6fnhWdeg-publication-show-evidence-container {
    display: none;
    margin-top: 20px;
}
.h6fnhWdeg-publication-show-evidence-container.h6fnhWdeg-publication-show-evidence-container-open {
    display: block;
}
.h6fnhWdeg-publication-evidence-bar {
    margin-left: 0px !important;
    margin-bottom: 15px !important;
}

.h6fnhWdeg-publication-show-evidence-link, h6fnhWdeg-publication-evidence-hide {
    margin-left: -10px;
}

.h6fnhWdeg-publications-evidence-table {
    font-size: 14px;
}
.h6fnhWdeg-publications-evidence-table thead th {
    background: #074e7e;
    color: #fff;
    padding: 10px;
    border-left: 1px solid #9cb8cb;
    text-align: center;
}
.h6fnhWdeg-publications-evidence-table td {
    padding: 5px 10px;
}
.h6fnhWdeg-publications-evidence-table thead .h6fnhWdeg-first-cell {
    text-align: right !important;
    border-left: none;
}

.h6fnhWdeg-publications-evidence-table p {
    margin: 5px 0px;
}

.h6fnhWdeg-publication-buttons {
    width: 190px;
    text-align: center;
    padding-top: 23px !important;
}
.h6fnhWdeg-publication-content {
    padding-top: 20px !important;
}
.h6fnhWdeg-publication-accept {
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
}
.h6fnhWdeg-publication-reject {
    font-size: 14px;
    font-weight: 600;
}
.h6fnhWdeg-publication-undo {
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
}
.h6fnhWdeg-publication-score {
    display: inline-block;
    background: #fffdd4;
    border-radius: 4px;
    text-align: center;
    padding: 7px 15px;
    line-height: 1.2em;
    margin-top: 20px;
    font-size: 14px;
}
.h6fnhWdeg-publication-score strong {
    display: block;
    font-size: 28px;
    text-align: center;
    padding: 10px 0px 10px 0px;
}
.h6fnhWdeg-publication-content p {
    line-height: 1.1em;
}

.h6fnhWdeg-publication-row-buttons {
    float: right;
}
.h6fnhWdeg-publication-row-buttons a {
    background-color: #ffffff;
    font-size: 14px;
    border: 1px solid #ccc;
    margin-left: 10px;
    color: #333333;
}
.h6fnhWdeg-publication-show-evidence-container {
    font-size: 14px;
}
.h6fnhWdeg-publication-show-evidence-link {
    color: #337ab7;
    cursor: pointer;
}
.h6fnhWdeg-publication-show-evidence-link.h6fnhWdeg-publication-evidence-show::before {
    content: '-';
    padding: 10px;
    font-size: 26px;
}

.h6fnhWdeg-publication-show-evidence-link.h6fnhWdeg-publication-evidence-hide::before {
    content: '+';
    padding: 10px;
    font-size: 26px;
}

.h6fnhWdeg-publication-show-evidence-link {
    text-decoration: none !important;
}
.h6fnhWdeg-publication-show-evidence-link:hover {
    text-decoration: none;
}

.h6fnhWdeg-publication-author-highlighted {
    background-color: #fffdd4;
}

.h6fnhWdeg-evidence-score-popup-container {
    text-align: left;
    max-width: 300px;
}

.h6fnhWdeg-reciter-type {
    display: inline-block;
    border: 1px solid #dedcdc;
    background: #eaeae1;
    border-radius: 2px;
    text-align: center;
    padding: 5px 5px;
    line-height: 1.0em;
    margin-top: 8px;
    font-size: 13px;
}

.h6fnhWdeg-publication-undo {
    color: #777;
    background-color: #ffffff;
    border-color: #ccc;
}
.h6fnhWdeg-publication-undo:hover {
    color: #333;
    border-color: #999;
}
